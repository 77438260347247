<template>
  <v-container id="user-profile" fluid tag="section">
    <!-- Start Page Title Area -->
    <div class="page-title-small">
      <div class="container">
        <div class="page-title-text">
          <h2>{{ user.company }}</h2>
          <p>
            {{ user.contact }} -
            <a :href="'mailto:' + user.email">{{ user.email }}</a> -
            {{ user.phone }}
          </p>
        </div>
      </div>
    </div>
    <!-- End Page Title Area -->

    <payout-form
      ref="payoutForm"
      :user_id="user_id"
      @itemSaved="refreshStats"
    ></payout-form>

    <!-- Start Content Area -->
    <section class="features-area ptb-30">
      <div class="container">
        <div v-if="stats" class="row">
          <div class="col col-12 col-lg-4 balances">
            <v-card
              elevation="2"
              @click="$router.push('/pages/users/' + user_id + '/bookings')"
            >
              <v-card-title class="title">
                <p class="ml-4 subheading text-left">
                  {{ $t("user.Total Balances") }}
                </p>
              </v-card-title>
              <v-card-text>
                <h3
                  v-for="item in stats.balances.values"
                  :key="'balance_' + item.currency"
                  class="font-weight-light text--primary text-right"
                >
                  {{ item.balance }} <small>{{ item.currency }}</small>
                  <br />
                  {{ $t("payouts.redeemable") }} {{ item.redeemable }}:
                  <small>{{ item.currency }}</small>
                </h3>
              </v-card-text>
              <v-card-actions>
                <chartist
                  v-if="stats.balances.data.data"
                  :data="stats.balances.data"
                  :options="chartOptions"
                  type="Line"
                  style="height: 100px"
                />
              </v-card-actions>
            </v-card>
          </div>
          <div class="col col-12 col-lg-4 transactions">
            <v-card
              elevation="2"
              @click="
                $router.push(
                  '/pages/users/' + user_id + '/transactions/?status=completed'
                )
              "
            >
              <v-card-title class="title">
                <p class="ml-4 subheading text-left">
                  {{ $t("transactions.Total transactions") }}
                </p>
              </v-card-title>
              <v-card-text>
                <h3 class="font-weight-light text--primary text-right">
                  {{ stats.transactions.count }}
                </h3>
              </v-card-text>
              <v-card-actions>
                <chartist
                  v-if="stats.transactions.data"
                  :data="stats.transactions.data"
                  :options="chartOptions"
                  type="Line"
                  style="height: 100px"
                />
              </v-card-actions>
            </v-card>
          </div>
          <div class="col col-12 col-lg-4 subscriptions">
            <v-card
              elevation="2"
              @click="
                $router.push('/pages/users/' + user_id + '/subscriptions')
              "
            >
              <v-card-title class="title">
                <p class="ml-4 subheading text-left">
                  {{ $t("subscriptions.Total subscriptions") }}
                </p>
              </v-card-title>
              <v-card-text>
                <h3 class="font-weight-light text--primary text-right">
                  {{ stats.subscriptions.count }}
                </h3>
              </v-card-text>
              <v-card-actions>
                <chartist
                  v-if="stats.subscriptions.data"
                  :data="stats.subscriptions.data"
                  :options="chartOptions"
                  type="Line"
                  style="height: 100px"
                />
              </v-card-actions>
            </v-card>
          </div>

          <div class="col col-12 col-lg-4 statements">
            <v-card elevation="2">
              <v-card-title class="title">
                <i class="fas fa-file-pdf fa-2x"></i>
                <p class="ml-4 subheading text-left">
                  {{ $t("user.account statements") }}
                </p>
              </v-card-title>
              <v-card-text>
                <v-select
                  v-if="stats.statements && stats.statements.length"
                  v-model="selected_statement"
                  :items="stats.statements"
                />
                <span v-else>{{ $t("user.no account statements") }}</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  v-if="stats.statements && stats.statements.length"
                  :disabled="!selected_statement"
                  @click="downloadStatement"
                >
                  {{ $t("download") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>

          <div class="col col-12 col-lg-4 payouts">
            <v-card elevation="2">
              <v-card-title class="title white--text">
                <i class="fas fa-hand-holding-usd fa-2x"></i>
                <p class="ml-4 subheading text-left">
                  {{ $t("payouts.payouts") }}
                </p>
              </v-card-title>
              <v-card-text style="min-height: 86px">
                <br />
                <div v-if="stats.payouts && stats.payouts.total.length">
                  {{ $t("payouts.total") }}:
                  <span
                    v-for="(item, index) in stats.payouts.total"
                    :key="'t' + index"
                  >
                    {{ item.amount }} {{ item.currency }}
                  </span>
                  <br />
                  {{ $t("payouts.pending") }}:
                  <span
                    v-for="(item, index) in stats.payouts.pending"
                    :key="'p' + index"
                  >
                    {{ item.amount }} {{ item.currency }}
                  </span>
                </div>
                <span v-else>{{ $t("payouts.no payouts") }}</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <div class="text-right w-100">
                  <v-btn color="primary" @click="requestPayout">
                    {{ $t("payouts.request payout") }}
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </section>
    <!-- End Content Area -->
  </v-container>
</template>

<script>
import payoutForm from "./PayoutForm.vue";

export default {
  components: { payoutForm },
  layout: "account",
  meta: {
    auth: true,
  },

  data: () => ({
    hover: false,
    user: {},
    user_id: null,
    stats: null,
    selected_statement: null,
    chartOptions: {
      low: 0,
      showArea: true,
      fullWidth: true,
      showPoint: false,
      height: "100px",
      axisX: {
        showLabel: false,
        showGrid: false,
        offset: 0,
      },
      axisY: {
        showLabel: false,
        showGrid: false,
        offset: 0,
      },
    },
  }),

  created() {
    this.user_id = this.$route.params.id;
  },
  mounted() {
    this.get("/users/" + this.user_id + "/stats/").then((data) => {
      this.stats = data;
    });
    this.get("/users/" + this.user_id).then((data) => {
      this.user = data;
    });
  },

  methods: {
    downloadStatement() {
      this.download(
        "/users/" + this.user_id + "/statement/" + this.selected_statement,
        this.selected_statement + ".pdf"
      ).catch((error) => {
        alert(error.message);
      });
    },
    requestPayout() {
      this.$refs.payoutForm.showForm();
    },
    refreshStats() {
      this.get("/users/" + this.user_id + "/stats/").then((data) => {
        this.stats = data;
      });
    },
  },
};
</script>

<style lang="scss">
.v-card {
  word-wrap: normal;
  white-space: normal;
}

.subscriptions .ct-series-a .ct-line {
  stroke: green;
}
.subscriptions .ct-series-a .ct-area {
  fill: greenyellow;
}

.transactions .ct-series-a .ct-line {
  stroke: blue;
}
.transactions .ct-series-a .ct-area {
  fill: cyan;
}
</style>
