<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="50vw"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("payouts.request payout") }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="form.amount"
              :label="$t('payouts.amount') + ' (EUR)'"
              :rules="rules.amount"
              append-icon="mdi mdi-plus-circle-multiple-outline"
              @click:append="setMaxAmount"
            ></v-text-field>

            <v-text-field
              v-model="form.iban"
              :label="$t('payouts.iban')"
              :rules="rules.iban"
            ></v-text-field>

            <v-text-field
              v-model="form.bic"
              :label="$t('payouts.bic')"
              :rules="rules.bic"
            ></v-text-field>
          </v-container>
        </v-card-text>

        <v-card-actions class="p-4">
          <v-spacer></v-spacer>
          <v-btn color="gray" :disabled="sending" @click="closeForm">{{
            savedItem ? $t("button.Close") : $t("button.Cancel")
          }}</v-btn
          >&nbsp;
          <v-btn
            v-if="!savedItem"
            type="submit"
            :disabled="sending"
            color="green"
            >{{ $t("payouts.request payout") }}</v-btn
          >
        </v-card-actions>
      </v-form>

      <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
        $t(errorMessage)
      }}</v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0"
        >{{ $t(savedItem.message) }}
      </v-alert>
      <v-progress-linear
        v-if="sending"
        color="#033"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-card>
  </v-dialog>
</template>

<script>
import validate from "@/plugins/validate";
import crudMixin from "../../../mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: {
    data: {
      type: Object,
      default: null,
    },
    user_id: {},
  },
  data() {
    return {
      apiQuery: "/users/" + this.user_id + "/payout/",
      categories: [],
      form: {},
      rules: {
        amount: [
          (v) => !!v || "Required.",
          (v) => {
            return v >= 5 && v <= this.balance ? true : "Bad amount";
          },
        ],
        iban: [
          (v) => !!v || "Required.",
          (v) => {
            return validate.iban.test(v) ? true : "Invalid iban";
          },
        ],
        bic: [
          (v) => !!v || "Required.",
          (v) => {
            return validate.bic.test(v) ? true : "Invalid bic";
          },
        ],
      },
    };
  },

  methods: {
    showForm() {
      this.get("/users/" + this.user_id + "/payout/meta").then((res) => {
        if (res) {
          this.form.iban = res.iban;
          this.form.bic = res.bic;
          this.form.currency = "EUR";
          this.balance = parseFloat(res.balance);
          this.max_amount = parseFloat(res.max_amount);
        }
        this.showFormDialog = true;
      });
    },
    setMaxAmount() {
      this.$set(this.form, "amount", this.max_amount);
    },
  },
};
</script>
